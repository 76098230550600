<template>
  <div>
    <!-- Show the create form -->
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      max-width="600"
      :persistent="isMakingRequest"
    >
      <v-card :loading="isMakingRequest">
        <v-card-title class="d-flex justify-space-between primary white--text pb-4">
          <div class="d-flex align-center">
            Audience Overlap
          </div>

          <!-- If there are more than one influencers -->
          <div
            v-if="selectedItems.length > 1"
            class="text-subtitle-2"
          >
            {{ selectedItems.length }} Influencers
          </div>
        </v-card-title>

        <v-card-text class="pt-6">
          <!-- If there are error messages -->
          <p
            v-for="(message, index) in errorMessages"
            :key="index"
            class="red--text"
          >
            {{ message }}
          </p>

          <div
            v-if="selectedItems.length > maxItems"
            class="red--text mb-3"
          >
            Add upto {{ maxItems }} accounts to compare
          </div>

          <template v-if="selectedItems.length">
            <v-slide-group class="mb-6">
              <v-slide-item
                v-for="influencer in selectedItems"
                :key="influencer.symbol"
              >
                <div>
                  <profile-chip
                    :data="influencer.data.preview || influencer.data"
                    :platform="influencer.data.platform"
                    class="mr-3"
                    closeable
                    @close="removeSelectedItem(influencer.symbol)"
                  />
                </div>
              </v-slide-item>
            </v-slide-group>
          </template>

          <div class="d-flex mt-3 mt-sm-0">
            <!-- show the input for searching terms -->
            <profile-selector
              @change="handleProfileChange"
              :platform="selectedPlatform"
              :hide-no-data="true"
              :label="shouldInputBeDisabled ? 'Maximum limit reached' : 'Search Profile'"
              :disabled="shouldInputBeDisabled"
              class="rounded-r-0"
              type="search"
              outlined
            />

            <!-- the select options for different platforms -->
            <platform-selector
              :value="selectedPlatform"
              :show-tiktok="false"
              @input="handlePlatformChange"
              :readonly="influencers.length > 0"
              class="rounded-l-0"
              outlined
            />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip
                label
                color="primary"
                v-on="on"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>

                {{ nFormatter(availableModuleUsage) }} Reports
              </v-chip>
            </template>

            <span>
              You have {{ availableModuleUsage }} reports available to generate
            </span>
          </v-tooltip>

          <v-spacer />

          <v-btn
            text
            color="primary"
            :disabled="isMakingRequest"
            @click="$emit('input', false)"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            :loading="isMakingRequest"
            :disabled="shouldCTABeDisabled"
            @click="handleSubmit"
          >
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import child components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ '@/blocks/common/ProfileChip')
const ProfileSelector = () => import(/* webpackChunkName: "profile-selector" */ "@/blocks/common/selectors/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "platform-selector" */ "@/blocks/common/selectors/PlatformSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "AudienceOverlapForm",

  // Register the child components
  components: {
    ProfileChip,
    ProfileSelector,
    PlatformSelector,
  },

  // Define the props
  props: {
    value: {
      type: Boolean,
      default: false
    },

    influencers: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  // Define the local data variables
  data: () => ({
    isMakingRequest: false,

    selectedPlatform: "instagram",

    minItems: constants.model.audienceOverlapReport.minimumItems,
    maxItems: constants.model.audienceOverlapReport.maximumItems,

    selectedItems: [],

    // The error messages for invalid inputs
    errorMessages: []
  }),

  // Define computable properties
  computed: {
    /**
     * Compute the available module usage
     *
     * @returns {Number}
     */
    availableModuleUsage() {
      return Math.floor(this.$store.getters["auth/availableModuleUsage"]("audience-overlap"))
    },

    /**
     * Whether or not to disable the input
     *
     * @return {Boolean}
     */
    shouldInputBeDisabled() {
      return this.selectedItems.length >= this.maxItems
    },

    /**
     * Whether or not to disable the CTA
     *
     * @returns {Boolean}
     */
    shouldCTABeDisabled() {
      return this.selectedItems.length < this.minItems || this.selectedItems.length > this.maxItems || this.errorMessages.length > 0 || this.isMakingRequest
    },

    /**
     * Get the platforms from the influencers
     *
     * @returns {Array}
     */
    platforms() {
      return Array.from(new Set(this.influencers.map(influencer => influencer.platform)))
    }
  },

  // Define watch properties
  watch: {
    influencers: {
      deep: true,
      handler(items) {
        // Reset the state
        this.resetState()

        // If there's no item
        if (!items.length) {
          return false
        }

        // If there are multiple platforms
        if (this.platforms.length > 1) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on a single platform at a time")
        }

        // If the platforms has anything but instagram or youtube
        if (this.platforms.some((platform) => !["instagram", "youtube"].includes(platform))) {
          // Add an error message
          this.errorMessages.push("You can only run social sentiment analysis on Instagram or Youtube")
        }

        // If there are more than the maximum items
        if (items.length > this.maxItems) {
          // Add an error message
          this.errorMessages.push(`You can only compare upto ${this.maxItems} profiles`)
        }

        // If there are no error messages
        if (this.errorMessages.length > 0) {
          // Stop further execution
          return
        }

        // Get the platform value from first item
        this.selectedPlatform = items[0].platform

        // Map through the influencers
        for (const item of items) {
          // Check if it's not present
          if (!this.selectedItems.some((search) => (search.data.username || search.data.account_id) === (item.username || item.account_id))) {
            // Push the item to the list
            this.selectedItems.push({
              symbol: Symbol(),
              data: item
            })
          }
        }
      },
    }
  },

  // Define local method functions
  methods: {
    /**
     * Handle the profile select event
     *
     * @return {void}
     */
    async handleProfileChange(selectedUser) {
      // If the value is falsy
      if (!selectedUser) return false

      // If the value is not an object
      if (typeof selectedUser !== 'object') return false

      // Check if it's not present in the selected items
      if (this.selectedItems.some((search) => search.data.value === selectedUser.value)) {
        return false
      }

      // Push the selected item to the list
      this.selectedItems.push({
        symbol: Symbol(),
        data: selectedUser
      })
    },

    /**
     * Change the selected platform value
     *
     * @param {String} value
     * @return {void}
     */
    handlePlatformChange(value) {
      this.selectedPlatform = value

      this.resetState()
    },

    /**
     * Reset the state of the component
     *
     * @return {void}
     */
    resetState() {
      this.selectedItems = []
      this.errorMessages = []
    },

    /**
     * Remove the selected item
     *
     * @param {Symbol} symbol
     * @return {void}
     */
    removeSelectedItem(symbol) {
      const itemIndex = this.selectedItems.findIndex((search) => search.symbol === symbol)

      if (itemIndex !== -1) {
        this.selectedItems.splice(itemIndex, 1)
      }
    },

    /**
     * Handle the click event for the compare button
     *
     * @return {void}
     */
    async handleSubmit() {
      // If a request is already being made
      if (this.isMakingRequest) {
        return false
      }

      // If the number of accounts is less than minimum
      if (this.selectedItems.length < this.minItems) {
        this.$store.dispatch("toasts/add", {
          text: `Please add at least ${this.minItems} profiles for the comparison`
        })

        return false
      }

      // If the number of accounts is more than maximum
      if (this.selectedItems.length > this.maxItems) {
        this.$store.dispatch("toasts/add", {
          text: `Please add at most ${this.maxItems} profiles for the comparison`
        })

        return false
      }

      // Show a loader
      this.isMakingRequest = true

      try {
        // Make the network request
        await axios({
          url: "/api/audience-overlaps",
          method: "POST",
          data: {
            platform: this.selectedPlatform,
            accounts: this.selectedItems.map((item) => item.data.idToQuery || item.data.username || item.data.account_id)
          }
        })

        // Show a toast
        this.$store.dispatch("toasts/add", {
          text: "Your report should be ready in a few minutes."
        })

        // Reset the state
        this.resetState()
        this.$emit("input", false)
        this.$emit("created")
      }
      // Catch the error
      catch (error) {
        logger({ type: "Audience Overlap", error })

        this.$store.dispatch("toasts/add", {
          text: error.response?.data?.message || "An error occurred!"
        })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.isMakingRequest = false
      }
    },
  }
}
</script>
